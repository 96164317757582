<template>
  <WeCard>
    <h5 class="custom-card-title">
      <i class="fas fa-sync"></i>
      <span
        >PAZARYERİ ÜRÜN FİYATLARI
        <i
          class="ml-2 fas fa-info-circle"
          v-tooltip.right="
            'Ürün detayında Pazaryeri fiyat listesini göstermek için kullanılır'
          "
        ></i
      ></span>
    </h5>
    <hr />

    <!-- Marketplace Product URL -->
    <WeInput
      placeholder="Ürün URL"
      v-bind:class="[data && data.length ? '' : 'mb-0']"
      v-bind:disabled="!isActive"
      v-model="priceData.url"
      v-on:keyup-enter="onCreateNew"
    >
      <template #prepend>
        <v-select
          style="min-width: 120px"
          label="title"
          placeholder="Pazaryeri"
          v-bind:disabled="!isActive"
          v-bind:options="marketplaceOptions"
          v-bind:clearable="false"
          v-bind:searchable="false"
          v-model="priceData.marketplace"
        >
          <template slot="no-options"> Sonuç Yok </template>
        </v-select>
      </template>
      <template #append>
        <button
          v-if="!createLoading"
          class="btn btn-sm btn-outline-primary"
          v-bind:disabled="!isActive"
          v-on:click="onCreateNew"
        >
          <div><i class="fas fa-plus"></i> Ekle</div>
        </button>
        <i class="fas fa-spinner fa-spin" v-else></i>
      </template>
    </WeInput>
    <!-- ./Marketplace Product URL -->

    <!-- Marketplaces -->
    <template v-if="data && data.length">
      <ul class="list-group">
        <li
          class="list-group-item"
          v-for="(marketplaceProduct, index) in data"
          v-bind:key="index"
        >
          <div class="row align-items-center">
            <!-- Marketplace Favicon -->
            <div class="col-auto" v-if="marketplaceProduct.marketplace">
              <img
                v-bind:src="marketplaceProduct.marketplace.logo"
                width="50"
              />
            </div>
            <!-- ./Marketplace Favicon -->
            <div class="col">
              <div class="text-truncate" style="max-width: 300px">
                <a v-bind:href="marketplaceProduct.url" target="_blank">{{
                  marketplaceProduct.url
                }}</a>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-flex">
                <!-- Price -->
                <button
                  v-if="marketplaceProduct.status == 'success'"
                  class="btn btn-sm btn-success"
                >
                  {{ marketplaceProduct.price | moneyFormat }}
                </button>
                <!-- ./Price -->

                <!-- Loading -->
                <button
                  v-else-if="marketplaceProduct.status == 'pending'"
                  class="btn btn-sm btn-primary ml-2"
                >
                  <i class="fas fa-spinner fa-spin"></i>
                </button>
                <!-- ./Loading -->

                <!-- Error -->
                <button
                  v-else-if="marketplaceProduct.status == 'error'"
                  class="btn btn-sm btn-danger ml-2"
                  v-tooltip.top="'Fiyat Alınamadı'"
                >
                  <i class="fas fa-exclamation-triangle"></i>
                </button>
                <!-- ./Error -->

                <!-- Error -->
                <button
                  class="btn btn-sm alert-success ml-2"
                  v-on:click="onUpdatePrice(marketplaceProduct)"
                  v-tooltip.top="'Fiyat Güncelle'"
                >
                  <i class="fas fa-sync"></i>
                </button>
                <!-- ./Error -->

                <!-- Remove -->
                <button
                  class="btn btn-sm alert-danger ml-2"
                  v-on:click="onRemove(marketplaceProduct, index)"
                >
                  <i class="fas fa-trash fa-sm"></i>
                </button>
                <!-- ./Remove -->
              </div>
            </div>
          </div>
        </li>
      </ul>
    </template>
    <!-- ./Marketplaces -->
  </WeCard>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "WeMarketplacePrice",
  props: {
    product: {
      default: null,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  model: {
    prop: "data",
  },
  sockets: {
    data: function (data) {
      this.getNodeProduct(data);
    },
  },
  data() {
    return {
      priceData: {
        marketplace: null,
        competitor_id: null,
        url: null,
        status: "pending",
        price: 0,
      },
      nodeProduct: null,
      createLoading: false,
      productNotFound: false,
    };
  },
  methods: {
    ...mapActions({
      getMarketplaceList: "marketplace/getList",
      loadPriceAnalysis: "priceAnalysis/load",
      getSingleProduct: "priceAnalysis/getSingleProduct",
      createProduct: "priceAnalysis/createProduct",
      createProductCompetitor: "priceAnalysis/createProductCompetitor",
    }),
    onCreateNew() {
      if (!this.createLoading) {
        const newData = helper.clone(this.priceData);

        if (newData.marketplace) {
          if (newData.url) {
            if (helper.isURL(newData.url)) {
              this.getOrCreateProduct(newData);
            } else {
              this.$toast.warning("Lütfen geçerli bir URL girin");
            }
          } else {
            this.$toast.warning("Lütfen fiyatı çekilecek ürün URL'i girin");
          }
        } else {
          this.$toast.warning("Lütfen Pazaryeri Seçin");
        }
      }
    },
    createCompetitor(newData) {
      if (this.nodeProduct) {
        this.createLoading = true;
        this.createProductCompetitor({
          requestData: {
            productId: this.nodeProduct.id,
            url: this.priceData.url,
          },
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              this.priceData.competitor_id = result.data.id;
              if (newData) {
                this.data.push(newData);
              }
            } else {
              this.$toast.error("Rakip Eklenemedi !");
            }
          },
          onFinish: () => {
            this.createLoading = false;
            this.clearData();
          },
        });
      }
    },
    getOrCreateProduct(newData = null) {
      this.getNodeProduct();

      if (!this.nodeProduct) {
        this.createProduct({
          form: {
            name: this.product.info.name,
            sku_no: this.product.info.stockCode,
          },
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              this.nodeProduct = {};
              this.nodeProduct.id = result.data.id;
              this.nodeProduct.skuNo = this.product.info.stockCode;
              this.nodeProduct.url = this.priceData.url;
              this.productNotFound = false;

              this.createCompetitor(newData);
            }
          },
        });
      } else {
        this.createCompetitor(newData);
      }
    },
    clearData() {
      this.priceData = {
        marketplace: null,
        competitor_id: null,
        url: null,
        status: "pending",
        price: 0,
      };
    },
    checkSelectable(marketplaceId) {
      return !this.currentMarketplaces.includes(marketplaceId);
    },
    onRemove(marketplaceProduct, index) {
      this.$swal({
        title: "İşlemi Onaylıyor Musunuz ?",
        text: "Pazaryeri fiyat alma işlemi durdurulacak ve ürün detaydan kaldırılacak.",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.data.splice(index, 1);
        }
      });
    },
    getNodeProduct() {
      if (!this.productNotFound) {
        if (this.product.info.stockCode) {
          this.getSingleProduct({
            skuNo: this.product.info.stockCode,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.nodeProduct = result.data.product;
                this.getNodeCompetitors();
              }
            },
            onError: (error) => {
              if (error && error.status == 404) {
                this.productNotFound = true;
              }
            },
          });
        }
      }
    },
    getNodeCompetitors() {
      if (this.nodeProduct) {
        if (
          this.nodeProduct.competitors &&
          this.nodeProduct.competitors.length
        ) {
          const nodeProductCompetitors = this.nodeProduct?.competitors || [];
          const newData = this.data.map((item) => {
            const foundCompetitor = nodeProductCompetitors.find(
              (comp) => comp.url == item.url
            );

            if (foundCompetitor) {
              item.status = foundCompetitor.status;
              item.price = foundCompetitor.price;
              item.competitor_id = foundCompetitor.id;
              item.update_at = this.$filters.dateFormat(
                foundCompetitor.updateAt
              );
            }

            return item;
          });

          this.$emit("input", newData);
        }
      }
    },
    onUpdatePrice(marketplaceProduct) {
      marketplaceProduct.status = "pending";
      this.getNodeProduct();
    },
  },
  computed: {
    ...mapState(["marketplace"]),
    currentMarketplaces() {
      let result = [];

      if (this.data && this.data.length) {
        result = this.data.map((item) => item.marketplace.id);
      }

      return result;
    },
    marketplaceOptions() {
      let result = this.marketplace.list;

      if (result && result.length) {
        result = result.filter(
          (item) => !this.currentMarketplaces.includes(item.id)
        );
      }

      return result;
    },
    isActive() {
      return this.product && this.product.info && this.product.info.stockCode;
    },
  },
  mounted() {
    if (!this.marketplace.list.length) {
      this.getMarketplaceList();
    }
    this.loadPriceAnalysis();
    this.getNodeProduct();
  },
};
</script>